import { TransactionReceipt } from "@ethersproject/abstract-provider"
import { useWeb3React } from "@web3-react/core"
import { BigNumber } from "ethers"
import { useRouter } from "next/router"
import { useContext } from "react"
import { useSWRConfig } from "swr"
import useGawdsContract from "web/hooks/useGawdsContract"
import useGawdsContractData from "web/hooks/useGawdsContractData"
import { CacheKey } from "web/lib/cache-keys"
import { ContractData } from "web/lib/contract"
import { StoreAction, StoreContext, TransactionStatus } from "web/lib/store"

export default function useBuyGawd(initContractData: ContractData) {
    const router = useRouter()
    const { active, account, chainId } = useWeb3React()
    const { mutate } = useSWRConfig()
    const contract = useGawdsContract(true)

    const gawdsContractData = useGawdsContractData(initContractData)

    const {
        transaction: [transactionStatus, dispatchTransactionStatus],
        connectWalletModal: [isOpen, dispatchModalState],
    } = useContext(StoreContext)

    async function fetchGasEstimate(quantity: number, value: BigNumber): Promise<BigNumber> {
        return new Promise((resolve, reject) => {
            contract.estimateGas.summon(quantity, { value }).then((res) => {
                const plusRes = res.mul(120).div(100) // 20% extra pls
                console.log("gasLimitEstimate", res.toString(), 'juiced =>', plusRes.toString())
                resolve(plusRes)
            }).catch((e) => {
                reject(e)
            })
        })
    }

    // function handleError(err) {
    //     dispatchTransactionStatus(['RESET'])
    //     if (err.code == 4001) {
    //         console.log("User rejected txn. that's cool bro")
    //     } else {
    //         const message = err?.data?.message || err?.message
    //         console.error('(!!) Metamask error', err.code, { err }, message)
    //         alert(`Uh oh, there was an error: ${err.code}\n\n${message}`)
    //     }
    // }

    // TODO: seems weird to define this inside `BuySection()`
    async function BuyGawd(quantity: number) {
        if (!active) {
            // open the connect wallet modal
            dispatchModalState(['OPEN'])
            return null
        }

        // Prevent dupe calls
        if (transactionStatus.status == TransactionStatus.Started || transactionStatus.status == TransactionStatus.Pending) {
            return
        }

        try {
            dispatchTransactionStatus([StoreAction.Update, {
                status: TransactionStatus.Started,
                quantity,
                txn: null
            }])

            const cost = gawdsContractData.price.mul(quantity)
            const gasLimitEstimate = await fetchGasEstimate(quantity, cost)

            // Small delay for portal enjoyment
            await delay(1000)

            const txn = await contract.summon(quantity, {
                value: cost,
                gasLimit: gasLimitEstimate,
            })

            dispatchTransactionStatus([StoreAction.Update, {
                status: TransactionStatus.Pending,
                txn: txn
            }])

            // wait for only 1 txn confirmation to proceed
            const receipt = await txn.wait(1)

            // Make the user wait a little bit to guarantee they see the summoning
            await delay(3000)

            onBuyGawdComplete(receipt)
        }
        catch(e) {
            dispatchTransactionStatus([StoreAction.Update, {
                status: TransactionStatus.Error
            }])
        }
    }

    function onBuyGawdComplete(receipt: TransactionReceipt = null) {

        if (receipt) {
            dispatchTransactionStatus([StoreAction.Update, {
                status: TransactionStatus.Complete,
                receipt: receipt,
            }])
        }
        else {
            dispatchTransactionStatus([StoreAction.Update, {
                status: TransactionStatus.Complete,
            }])
        }

        // const { tokenId } = receipt.events[0].args

        // Clear caches
        mutate(CacheKey.tokenCount(account, chainId))
        mutate(CacheKey.totalGawdSupply(chainId))
    }

    return {
        BuyGawd,
        onBuyGawdComplete
    }
}

export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}