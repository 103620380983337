const formats = [
  {
    extension: 'mp4',
    title: '4K animated video',
    subTitle: 'Behold ye mighty',
  },
  {
    extension: 'mp4',
    title: 'Holographic video',
    subTitle: 'For Looking Glass Portrait',
  },
  {
    extension: 'png',
    title: '3840 x 3840',
    subTitle: 'Perfect for avatars',
  },
  {
    extension: 'gif',
    title: '500 x 500',
    subTitle: 'Duh, why not?',
  },
]

const MediaFormatsSection = () => {
  return (
    <section id="holy-formats" className=" space-y-5">
      <h6 className="small-caps md:text-center">The most holy of formats</h6>

      <ul className="grid gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
        {formats.map((format, index) => {
          return (
            <li key={`${format.extension}-${index}`} className="flex md:col-span-1">
              <div className="flex-shrink-0 flex items-center justify-center w-16 bg-gray-500 text-white text-sm font-medium uppercase rounded-l-md">
                {format.extension}
              </div>
              <div className="flex-1 items-center justify-between border-t border-r border-b border-gray-800 bg-gray-800 rounded-r-md px-4 py-2">
                <p>{format.title}</p>
                <p className="text-gray-500">{format.subTitle}</p>
              </div>
            </li>
          )
        })}
      </ul>
    </section>
  )
}

export default MediaFormatsSection
