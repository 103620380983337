
import classNames from 'classnames'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import { useContext } from 'react'
import { StoreAction, StoreContext, TransactionStatus } from 'web/lib/store'

// https://stackoverflow.com/questions/71718731/why-am-i-getting-this-uselayouteffect-warning-not-in-a-test
const LordHoleScene = dynamic(
  () => import('web/components/three/GawdBlackHole'),
  { ssr: false }
)

export default function BannerSection({ initContractData }) {
  const {
    transaction: [transactionStatus, dispatchTranactionStatus],
  } = useContext(StoreContext)

  // const { getTimeRemaining } = usePortalShutdown(initContractData)

  const canPurchase = transactionStatus.status == TransactionStatus.Initial || transactionStatus.status == TransactionStatus.Error
  return (
    <section id="featured-images" className="text-center h-[16rem] md:h-[16rem] lg:h-[18rem] xl:h-[24rem]">
      <div className="mt-5 relative z-10 space-y-4 md:max-w-2xl mx-auto">
        <Link href="/" passHref={true}>
          <a>
            <Image src="/images/gawds-logo.svg" width="218" height="54" />
          </a>
        </Link>
        <div className={classNames(
          "[text-shadow:0_4px_8px_rgba(0,0,0,1)] transition-all duration-1000",
          transactionStatus.status == TransactionStatus.Started ||
          transactionStatus.status == TransactionStatus.Complete ||
          transactionStatus.status == TransactionStatus.Pending ? "opacity-0" : ""
        )}>
          The first generative holographic NFTs.<br />
          {/* {getTimeRemaining().timeForHumans("long")} until mint close. */}
        </div>
      </div>

      <div className="relative w-[200%] left-[-50%] md:w-[150%] md:left-[-25%] lg:w-full lg:left-[0%] top-[-140px] md:top-[-180px] z-0 aspect-video relative">
        {/* <div className={classNames(
          "absolute w-128 translate-x-[-50%] translate-y-[-50%] left-[50%] top-[50%] z-20 transition-all duration-1000 text-black [text-shadow:0_2px_2px_rgba(0,0,0,0.4)]",
          canPurchase ? "opacity-100" : "opacity-0"
        )}>
          {canPurchase && <>
            <p>Portal closes in...</p>
            <h2 className="text-black">{getTimeRemaining().timeForHumans("long")}&hellip;</h2>
          </>}
        </div> */}

        <div className={classNames(
          "absolute w-128 translate-x-[-50%] translate-y-[-50%] left-[50%] top-[50%] z-20 transition-all duration-1000",
          transactionStatus.status == TransactionStatus.Started ? "opacity-100" : "opacity-0"
        )}>
          {transactionStatus.status == TransactionStatus.Started && <>
            <h2 className="pb-2">Dark greetings...</h2>
            Confirm and await the summoning <br /> of your holographic being{transactionStatus.quantity == 1 ? '' : 's'}.
          </>}
        </div>

        <div className={classNames(
          "absolute w-128 translate-x-[-50%] translate-y-[-50%] left-[50%] top-[50%] z-20 transition-all duration-1000",
          transactionStatus.status == TransactionStatus.Pending ? "opacity-100" : "opacity-0"
        )}>
          {transactionStatus.status == TransactionStatus.Pending && <>
            <h2 className="pb-2">Summoning</h2>
            Your Gawd{transactionStatus.quantity > 1 ? 's' : ''} may take several minutes to summon directly to your wallet.
            This page will update when complete or check{' '}
            <a href={`https://etherscan.io/tx/${transactionStatus.txn?.hash}`} target="_blank">Etherscan</a> for the status.
          </>}
        </div>

        <div className={classNames(
          "absolute w-128 translate-x-[-50%] translate-y-[-50%] left-[50%] top-[50%] z-20 transition-all duration-1000",
          transactionStatus.status == TransactionStatus.Complete ? "opacity-100" : "opacity-0"
        )}>
          {transactionStatus.status == TransactionStatus.Complete && <>
            <h2 className="pb-4">Summoning Complete</h2>
            <div className="space-x-4">
              <Link href="/wallet">
                <button className="primaryButton" onClick={() => {
                  dispatchTranactionStatus([StoreAction.Reset, {}])
                }}>
                  View Gawd{transactionStatus.quantity > 1 ? 's' : ''}
                </button>
              </Link>
            <button className="secondaryButton" onClick={() => {
              dispatchTranactionStatus([StoreAction.Reset, {}])
            }}>
              Summon More Gawds
            </button>
            </div>
          </>}
        </div>

        <LordHoleScene />
      </div>
    </section>
  )
}
