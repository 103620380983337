import { useContext, useEffect, useState } from 'react'
import { ethers, BigNumber } from 'ethers'
import classNames from 'classnames'
import { useWeb3React } from '@web3-react/core'
import { MinusIcon, PlusIcon } from '@heroicons/react/solid'

import { formatEtherscanLink } from 'web/lib/chain-utils'
import { TransactionStatus, StoreContext } from 'web/lib/store'

import styles from './BuySection.module.css'
import useETHBalance from 'web/hooks/useETHBalance'
import useGawdsContractData from 'web/hooks/useGawdsContractData'
import usePortalShutdown from 'web/hooks/usePortalShutdown'
import { MathUtils } from 'three'
import { ContractData } from 'web/lib/contract'
import useBuyGawd from './useBuyGawd'
import Image from 'next/image'

function calculateTotalCost(price: BigNumber, quantity) {
  const parsedQuantity = quantity ? Number(quantity) : 1

  return ethers.utils.formatEther(price.mul(parsedQuantity))
}

interface BuySectionProps {
  initContractData: ContractData
}

export default function BuySection({ initContractData }: BuySectionProps) {
  const { account, library, active, chainId } = useWeb3React()

  const {
    transaction: [transactionStatus, dispatchTransactionStatus],
  } = useContext(StoreContext)

  const gawdsContractData = useGawdsContractData(initContractData)
  const portalShutdown = usePortalShutdown(initContractData)
  const { BuyGawd } = useBuyGawd(initContractData)
  const ethBalance = useETHBalance()

  const [quantity, setQuantity] = useState<number>(1)

  const maxSupply = portalShutdown.getMaxSupply()
  const supplyRemaining = portalShutdown.remainingSupply

  const percentageSold: string = (MathUtils.clamp(gawdsContractData.soldSupply / maxSupply, 0, 1) * 100).toFixed(2) + '%'

  let maxPurchaseQuantity = 10
  if (maxPurchaseQuantity > supplyRemaining) {
    maxPurchaseQuantity = supplyRemaining
  }

  const etherscanLink = formatEtherscanLink({ type: 'account', chainId: chainId, hash: process.env.CONTRACT_ADDRESS })

  const currentCost = ethers.utils.parseEther(calculateTotalCost(gawdsContractData.price, quantity))
  const sufficientBalance = ethBalance.gte(currentCost)
  const isPendingPurchase = transactionStatus.status == TransactionStatus.Started || transactionStatus.status == TransactionStatus.Pending || transactionStatus.status == TransactionStatus.Complete

  function onQuantityChange(event) {
    const { value } = event.currentTarget
    setQuantity(value)
  }

  function onIncreaseQuantity() {
    setQuantity(MathUtils.clamp(quantity + 1, 1, maxPurchaseQuantity))
  }

  function onDecreaseQuantity() {
    setQuantity(MathUtils.clamp(quantity - 1, 1, maxPurchaseQuantity))
  }

  async function onBuyClick(event) {
    event.preventDefault()

    // Prevent dupe clicks
    if (!isPendingPurchase && sufficientBalance) {
      await BuyGawd(quantity)
    }
  }

  return (
    <section id="buy-section" className={classNames(
      "mx-auto mt-8 lg:w-3/5 relative z-10 transition-all duration-900 ease-out flex flex-col",
      isPendingPurchase ? "opacity-0" : ""
    )}>
      {portalShutdown.canPurchase &&
        <p className="bg-green-300 bg-opacity-90 py-1 px-6 text-center rounded-xl mb-4 mx-auto animate-pulse text-black">
          <span className="font-bold">50% off remaining Gawds!</span><br className=" md:hidden" />
          {' '}<a href="https://chad.mirror.xyz/5JHjjjs6isGr8_0do-Zd_6j1pmOFFvMP1-JaQCufZjU" target={'_blank'}>We reimburse in {portalShutdown.getTimeRemaining().timeForHumans("long")}</a>
        </p>}

      <div className="bg-gray-800 rounded-xl p-4">

        <div className="space-x-5 text-white">
          {portalShutdown.canPurchase && (
            <form
              className="space-y-5 md:grid md:grid-cols-2 gap-5 md:space-y-0"
              onSubmit={onBuyClick}>

              <fieldset className="relative grid grid-cols-5 height-20">
                <label
                  className="absolute col-start-1 col-span-5 z1 text-center text-gray-500 text-s w-full pt-1"
                  htmlFor="quantity">
                  Amount to buy
                </label>

                <input
                  id="quantity"
                  name="quantity"
                  type="number"
                  className="block col-start-1 col-span-5 text-black text-5xl text-center rounded-lg p-5"
                  step="1"
                  min="1"
                  max={maxPurchaseQuantity}
                  value={quantity}
                  onChange={onQuantityChange} />

                <button
                  className="absolute z1 right-5 top-8 md:top-10 h-10 w-10 bg-red-500 rounded-full"
                  onClick={(event) => {
                    event.preventDefault()
                    onIncreaseQuantity()
                  }}>
                  <PlusIcon className="w5 h5" aria-hidden="true" />
                </button>

                <button
                  className="absolute z1 left-5 top-8 md:top-10 h-10 w-10 bg-gray-500 rounded-full"
                  onClick={(event) => {
                    event.preventDefault()
                    onDecreaseQuantity()
                  }}>
                  <MinusIcon className="w5 h5" aria-hidden="true" />
                </button>
              </fieldset>

              <fieldset className="flex">
                <button type="submit" className={classNames(styles.primary, 'flex-auto')}>
                  <div className="px-4 py-5 sm:p-6">
                    <div className="text-2xl font-serif block">
                      {active && !sufficientBalance &&
                        <span>Not enough ETH!</span>
                        ||
                        <span>Summon {quantity} {Number(quantity) === 1 ? 'Gawd' : 'Gawds'}</span>
                      }
                    </div>
                    <div>{ethers.utils.formatEther(currentCost)} ETH</div>
                  </div>
                </button>
              </fieldset>
            </form>
          )}

          {!portalShutdown.canPurchase && (
            <button type="submit" className={classNames(styles.primary, 'w-full')}>
              <a href="https://opensea.io/collection/gawds" target="_blank" className="no-underline">
                <div className="px-4 py-5 sm:p-6">
                  <div className="text-2xl font-serif block">Visit OpenSea</div>

                  <div>Sold out!</div>
                </div>
              </a>
            </button>
          )}
        </div>

        <div className="w-full mt-8">
          <div id="progress-bar" className="relative h-1 flex w-full bg-gray-500">
            <div className="bg-red-500" style={{ width: percentageSold, transition: 'width 1s ease-out' }}></div>
          </div>

          <div id="progress-stats" className="flex mt-3 text-sm text-gray-500 uppercase tracking-wider">
            <div className="flex-1 text-left">{Number(gawdsContractData.soldSupply).toLocaleString()} summoned</div>

            <div className="flex-1 text-center">{percentageSold}</div>

            <div className="flex-1 text-right">{Number(supplyRemaining).toLocaleString()} left</div>
          </div>
        </div>
      </div>

      {portalShutdown.canPurchase && (
        <div className="text-center mt-2 text-gray-400 flex flex-row items-center justify-center space-x-4">
          <div className="w-12">
            <Image src="https://lookingglassfactory.com/img/logomark.png" width="100%" height="100%" layout="responsive" objectFit="contain" />
          </div>
          <div className="text-sm">
            Mint a Gawd to enter the <a href="https://chad.mirror.xyz/5JHjjjs6isGr8_0do-Zd_6j1pmOFFvMP1-JaQCufZjU" target={'_blank'} className={styles.rainbow}>Looking Glass raffle!</a>
          </div>
        </div>)}
    </section>
  )
}
