import Gawd from 'web/lib/gawd'
import useSWR from 'swr'
import { useEffect, useState } from 'react'

export default function useLoadGawdMetadata(gawd: Gawd, skipLoad: boolean = false): Gawd {
  const [loaded, setLoaded] = useState<boolean>(false)
  const canLoadGawd = (!skipLoad || loaded) && gawd && gawd.id() && !gawd.loaded

  const { data } = useSWR(canLoadGawd ? `/api/gawd/${gawd.id()}/metadata` : null, async function () {
    return await loadGawdMetadata(gawd.id())
  })

  useEffect(() => {
    if (data && data.loaded) {
      setLoaded(true)
    }
  }, [data])

  return data || gawd
}

export async function loadGawdMetadata(id: number): Promise<Gawd> {
  if (!id) return null
  const gawd = new Gawd({ id: id })
  const response = await fetch(`/api/gawds/${id}`)
  gawd.data = await response.json()
  gawd.loaded = true
  return gawd
}