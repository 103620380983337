import styles from './ClergySection.module.css'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const ClergySection = () => {
  return (
    <section id="clergy" className={`${styles.clergy} md:px-4 pt-20 pb-24 mx-auto relative`}>
      <img src="/images/gawd-eye-golden.png" height="145" width="245" alt="" className={styles.gawdEyeGolden} />

      <img src="/images/gawd-eye-reptilian.png" height="237" width="145" alt="" className={styles.gawdEyeReptilian} />

      <div className="bg-gray-800 rounded-lg py-24 px-4 md:px-24 lg:px-64 space-y-4 md:text-center md:space-y-8">
        <h1>We are the clergy</h1>
        <p>
          <a href="https://twitter.com/chadsnuts" target="_blank">Gawdsnuts</a>{' ✧ '}
          <a href="https://twitter.com/caseypugh" target="_blank">gpu</a>{' ✧ '}
          <a href="https://twitter.com/jamiew" target="_blank">gawdubz</a>{' ✧ '}
          <a href="https://twitter.com/di0xgenes" target="_blank">di0xgenes</a>{' ✧ '}
          <a href="https://twitter.com/TheDubpace" target="_blank">dubpace</a>
        </p>
        <p>
          Devout individuals building products and culture for the dark web since MCMXCVIII. This project is meant to grow and evolve with community feedback. We also plan to release open-source tools and guides to help other NFT creators build better, more reliable products.
        </p>

        <p className="pb-6"> {/* needs bottom border or links are covered by eyeballs */}
          Find us on <a href={publicRuntimeConfig.discordInviteUrl} target="_blank">Discord</a> or <a href="mailto:deity@gawds.xyz" target="_blank" >email us</a>.
        </p>
      </div>

      <img src="/images/gawd-eye-slime.png" height="195" width="276" alt="" className={styles.gawdEyeSlime} />

      <img src="/images/gawd-eye-blue.png" height="303" width="311" alt="" className={styles.gawdEyeBlue} />
    </section>
  )
}

export default ClergySection
