import classNames from "classnames"
import Image from "next/image"
import Link from "next/link"
import { useContext, useMemo, useRef } from "react"
import { MathUtils } from "three"
import useLoadGawdMetadata from "web/hooks/useLoadGawdMetadata"
import useOnScreen from "web/hooks/useOnScreen"
import Gawd from "web/lib/gawd"
import { StoreContext, TransactionStatus } from "web/lib/store"
import GawdPlayer from "../GawdPlayer"
import TamaGawdtchi from "../TamaGawdtchi"
import useEnvironment from "web/hooks/useEnvironment"

const gawdPromoIds = [1, 2, 3, 4, 5]

export default function BloodGameSection({ }) {
    const gawdRef = useRef()
    // const gawdVisible = useOnScreen(gawdRef)
    const gawdId = useMemo(() => gawdPromoIds[MathUtils.randInt(0, gawdPromoIds.length)], [])
    const { isMainnetLive } = useEnvironment()
    const gawd = useLoadGawdMetadata(new Gawd({ id: gawdId }), false)

    const {
        transaction: [transactionStatus],
    } = useContext(StoreContext)

    const steps = [
        {
            title: "Join a Cult",
            icon: "/images/cult-bind-icon.svg",
            description: <>Visit the Sanctuary and bind your wallet to the Cult of your choice.</>
        },
        {
            title: "Play Tamagawchi",
            icon: "/images/gawd-health-icon.svg",
            description: "Visit each Gawd and keep its worship meter full with the four praise actions."
        },
        {
            title: "Revive your Gawds",
            icon: "/images/skull.svg",
            description: "If you don't keep your Gawd healthy, it will perish and can be revived after a cooldown period."
        },
        {
            title: "Get blessed with $Blood",
            icon: "/images/blood-icon.svg",
            description: "Depending on your overall Gawds happiness, you will earn $Blood every four hours."
        },
        {
            title: "Buy Boons",
            icon: "/images/boons/crystal4.svg",
            description: "Spend your $Blood at the Exchange on various boons to enhance your worshipping experience."
        },
    ]

    return (
        <section className={classNames(
            "space-y-16 transition-opacity duration-1000",
            transactionStatus.status == TransactionStatus.Started ? "opacity-30" : "opacity-100"
        )}>
            {/* Header */}
            <div className="text-center max-w-3xl mx-auto space-y-2">
                <div className="uppercase bg-red-500 bg-opacity-30 text-red-500 rounded-xl p-1 px-4 text-sm inline">
                    {isMainnetLive ? 'Polygon Mainnet' : 'Launching Soon'}
                </div>
                <h1>Play the Blood Game</h1>
                <p className="pt-4">
                    {/* We're actively building a whole new layer of interactivity for Gawd owners.  */}
                    We created a handful of smart contracts on Polygon L2 with gas-free meta-transactions that bring a whole new layer of interactivity for Gawd owners.
                    Keep your Gawds happy, earn the <span className="text-white font-bold">$Blood</span> token, and use your tokens to buy in-game loot and enhancements for your Gawds.
                </p>
            </div>

            <div className="flex flex-col lg:flex-row space-y-6">
                <div ref={gawdRef} className="lg:w-3/5">
                    {gawd && gawd.loaded && <>
                        <GawdPlayer
                            data={gawd.data}
                            video={true}
                            toggleModes={true}
                            className="w-4/5 pt-4/5 md:w-4/5 md:pt-4/5 z-0 cursor-pointer"
                        />
                        <TamaGawdtchi id={gawd.id()} />
                    </>}
                </div>
                <div className="lg:w-2/5">
                    <div className="bg-gray-800 p-6 rounded-xl">
                        <div className="font-serif text-2xl text-center text-white">How to worship Gawds</div>
                        <p className="text-gray-500 text-center text-sm">
                            A brief primer on the proper care of your Gawds in order to earn $Blood. <a href="https://gawds.notion.site/Blood-Token-dee95904528144bd8552c02506731f30" target="_blank">More in our docs</a>.
                        </p>

                        <div className="pt-6 space-y-6">
                            {steps.map((step, i) =>
                                <div key={i} className="flex flex-row">
                                    <div className="w-16">
                                        <Image src={step.icon} width={41} height={41} />
                                    </div>
                                    <div className="space-y-1 text-sm flex-1">
                                        <div className="font-bold text-white">
                                            {step.title}
                                        </div>
                                        <div className="">
                                            {step.description}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {isMainnetLive &&
                            <Link href={`/wallet`}>
                                <button className={classNames("primaryButton uppercase w-full mt-6")}>
                                    Play the Game
                                </button>
                            </Link>}

                        {!isMainnetLive &&
                            <button className={classNames("primaryButtonAlt uppercase w-full mt-6")}>
                                Mainnet Launching Soon
                            </button>}
                    </div>
                </div>
            </div>
        </section>
    )
}
