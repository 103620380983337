import Image from 'next/image'
import Link from 'next/link'

import styles from './AnatomySection.module.css'

const AnatomySection = () => {
  return (
    <section className="mx-auto">
      <div className="w-full mt-16 md:text-center md:space-y-8">
        <h1>Anatomy of a GAWD</h1>
        <h5>Hand drawn, randomly assembled &amp; light-field rendered</h5>

        <div className="block md:hidden content mt-4">
          <Image src="/images/alchemy.svg" alt="" width="450" height="350" className="object-contain" />
        </div>
        <p className="mt-4 leading-relaxed md:mx-56">
          Each Gawd is a random composition of 148 parts across 6 categories, rendered with Unity in the
          highest-possible quality for enjoyment on 2D and 3D devices alike. Gawds are ERC-721 tokens (NFTs) minted on
          the Ethereum blockchain. Their media assets are redundantly stored on IPFS.
        </p>
      </div>

      <div className="mt-8 space-y-4 md:mx-32 md:grid md:grid-cols-4 md:grid-rows-3 md:gap-y-10 md:grid-flow-row-dense">
        <div className="hidden md:block md:flex md:content-center md:justify-center md:col-start-2 md:col-span-2 md:row-span-3">
          <Image src="/images/alchemy.svg" alt="" width="450" height="350" className="object-contain" />
        </div>

        <div className="md:space-y-2 md:col-start-1 md:col-start-1 md:col-span-1 md:text-right">
          <h6 className={styles.header}>1 Realm</h6>
          <div className={styles.description}>Zone a Gawd coalesced</div>
          <div className={styles.quantity}>22 available</div>
        </div>

        <div className="md:space-y-2 md:col-start-1 md:col-start-1 md:col-span-1 md:text-right">
          <h6 className={styles.header}>0-2 Auras</h6>
          <div className={styles.description}>Unique energy emitted</div>
          <div className={styles.quantity}>30 available</div>
        </div>

        <div className="md:space-y-2 md:col-start-1 md:col-start-1 md:col-span-1 md:text-right">
          <h6 className={styles.header}>1 Composition</h6>
          <div className={styles.description}>Physical body archetype</div>
          <div className={styles.quantity}>30 available</div>
        </div>

        <div className="md:space-y-2">
          <h6 className={styles.header}>1 Oculus</h6>
          <div className={styles.description}>Perception unit</div>
          <div className={styles.quantity}>35 available</div>
        </div>

        <div className="md:space-y-2">
          <h6 className={styles.header}>1 Accent</h6>
          <div className={styles.description}>Gawd flair</div>
          <div className={styles.quantity}>31 available</div>
        </div>

        <div className="md:space-y-2">
          <h6 className={styles.header}>Conveyance</h6>
          <div className={styles.description}>Movement of method</div>
          <div className={styles.quantity}>Procedural</div>
        </div>
      </div>
    </section>
  )
}

export default AnatomySection
