import styles from './LookingGlassSection.module.css'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const LookingGlassSection = () => {
  return (
    <section
      id="looking-glass-section"
      className="space-y-10 mx-auto md:flex md:items-center md:gap-14"
    >
      <div className="md:w-full md:order-0">
        <div className={styles.vimeoEmbedWrap}>
          <iframe
            src="https://player.vimeo.com/video/586372196?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=51f6585bc8&amp;autoplay=1&amp;background=1"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            className={styles.vimeoEmbed}
            title="GAWD - Looking Glass Preview"
          ></iframe>
        </div>
      </div>

      <div className={`${styles.copy} md:w-5/6 space-y-8`}>
        <h2>The first generative holographic NFT</h2>
        <p>
          Each Gawd is summoned with 4K video, PNG and GIF formats. But the truly awe-inspiring format is the Gawd's holographic form:
          A light field capture of 48 different angles of a 3D scene at 60fps made for the <a href="https://lookingglassfactory.com/" target="_blank">Looking Glass Portrait</a> display.
        </p>
        <p>
          We also developed a custom viewer so you can see your holographic Gawds on the web in anaglyph or interactive 2D (like Facebook 3D photos). Check out an <a href="https://gawds.s3.amazonaws.com/summon/gawds/79c1311acd3832093d12be3e8da2fa760bcca81f3263a2ee6d6c76d8f16492eb/Bright-Idle-Animation-3840x3840-LKG-HiResPortrait.png" target="_blank">
            example light field capture
          </a> to see how it looks under the hood.
        </p>
        {/* <div className={`${styles.giveaway} text-sm bg-red-900 text-white p-3 rounded-md`}>
          LOOKING GLASS GIVEAWAYS - Follow us on Discord &amp; Twitter
          <p className="text-sm text-red-500">We'll be giving away 3 GAWDS embedded in Looking Glass portraits</p>
        </div> */}
      </div>
    </section>
  )
}

export default LookingGlassSection
