import { useWeb3React } from '@web3-react/core'
import { BigNumber, ethers } from 'ethers'
import useSWR from 'swr'

export default function useETHBalance() {
  const { library, chainId, active, account } = useWeb3React()

  const shouldFetch = active && !!library

  const { data } = useSWR(shouldFetch ? [account, chainId, 'ETHBalance'] : null, async (): Promise<BigNumber> => {
    return await library.getBalance(account)
  }, {
    refreshInterval: 1000 * 10, // keep checking
    onSuccess: () => {} // dont default output results
  })

  return data || ethers.utils.parseEther("1000")
}