import { NextSeo } from 'next-seo'

import Layout from 'web/components/Layout'
import BuySection from 'web/components/index/BuySection'
import BannerSection from 'web/components/index/BannerSection'
import AnatomySection from 'web/components/index/AnatomySection'
import ClergySection from 'web/components/index/ClergySection'
import MediaFormatsSection from 'web/components/index/MediaFormatsSection'
import LookingGlassSection from 'web/components/index/LookingGlassSection'

import { ContractData, getContractData } from 'web/lib/contract'
import BloodGameSection from 'web/components/index/BloodGameSection'
import { GetStaticProps } from 'next'

type HomeProps = {
  contractData: ContractData
}

export const getStaticProps: GetStaticProps = async (context) => {
  const props: HomeProps = {
    contractData: await getContractData()
  }

  return {
    props: props,
    revalidate: 60,
  }
}

export default function Home({ contractData }: HomeProps) {
  return (
    <Layout initContractData={contractData}>
      <NextSeo />
      {
        <div className="space-y-16 md:space-y-32">
          <BannerSection initContractData={contractData} />

          <BuySection initContractData={contractData} />

          <BloodGameSection />

          <LookingGlassSection />

          <MediaFormatsSection />

          <AnatomySection />

          {/* <RareAndPowerfulSection /> */}
          {/* {supply.isLive === true && <OverviewSection />} */}
          {/* <Roadmap /> */}
          {/* <WhatsNext /> */}
          {/* <BecomeAWorshipperSection /> */}

          <ClergySection />
        </div>
      }
    </Layout>
  )
}
